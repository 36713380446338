import mitt from "mitt";

import { wait } from "@smartrent/utils";

export const getDelayAmount = (
  { min, max }: { min: number; max: number } = { min: 350, max: 3500 }
) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const delay = async (amount?: number) => {
  return wait(amount ? amount : getDelayAmount());
};

export const getDataByPage: <TData>(
  data: TData[],
  page: number,
  perPage: number
) => TData[] = (data, page, perPage) => {
  return data.slice(
    page > 1 ? (page - 1) * perPage : 0,
    (page - 1) * perPage + perPage
  );
};

export type DeviceEventListener = ({
  eventName,
}: {
  eventName: string;
}) => void;
export const emitter = mitt<any>();
