if (process.env.NODE_ENV !== "development") {
  import("@sentry/browser").then((Sentry) => {
    const sentry_dsn = process.env.REACT_APP_SENTRY_DSN || "";
    if (sentry_dsn) {
      Sentry.init({
        dsn: sentry_dsn,
        environment: process.env.NODE_ENV,
        denyUrls: ["/play"],
      });
    }
  });
}

import faker from "faker";
import { lazy, Suspense } from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "react-native-gesture-handler";
import "resize-observer-polyfill/dist/ResizeObserver.global";
import "./debugGlobals";
import "./index.css";
import "./yupMethods";

import { ThemeProvider } from "@smartrent/ui";
import { disableIOSZoom } from "@smartrent/utils";

import { setupApi } from "./api";
import FullScreenLoader from "./components/FullScreenLoader";
import { worker } from "./mocks/browser";

import type { ThemeMode } from "@smartrent/ui";

faker.seed(1);
disableIOSZoom();
worker.start();

setupApi();

const App = lazy(() => import("./App"));

const colorScheme =
  (localStorage.getItem("colorScheme") as ThemeMode) ?? "dark";

render(
  <ThemeProvider initialMode={colorScheme}>
    <Suspense fallback={<FullScreenLoader />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  </ThemeProvider>,
  document.getElementById("root")
);
