import React from "react";
import { ActivityIndicator, View } from "react-native";

import { useTheme, Text } from "@smartrent/ui";

export default function FullScreenLoader() {
  const { colors } = useTheme();

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.pageBackground,
      }}
    >
      <ActivityIndicator size="large" color={colors.primary} />
      <Text
        style={{
          marginTop: 16,
          color: colors.primary,
        }}
        variation="semibold.title.two"
      >
        Loading
      </Text>
    </View>
  );
}
