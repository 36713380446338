import { emitter } from "../utils";

import type { DeviceAttributeProps, DeviceProps } from "@smartrent/core";

export const unitTemperature: number = 68;
export const unitHumidity: number = 32;

export const devices: DeviceProps[] = [
  {
    id: 1,
    name: "Front Door",
    internal_name: "Lock",
    room_id: "",
    type: "entry_control",
    installation_status: "complete",
    attributes: [
      {
        id: 123,
        remote_id: null,
        last_read_state: "true",
        last_read_state_changed_at: null,
        name: "locked",
      },
    ],
    inserted_at: "",
    online: true,
    warning: false,
    battery_powered: true,
    battery_level: 8,
    deleted_at: "",
    secure: true,
  },
  {
    id: 5,
    name: "Garage Door",
    internal_name: "Garage Door",
    room_id: "",
    type: "garage_door_controller",
    installation_status: "complete",
    attributes: [
      {
        id: 123,
        remote_id: null,
        last_read_state: "true",
        last_read_state_changed_at: null,
        name: "open",
      },
    ],
    inserted_at: "",
    online: true,
    warning: false,
    battery_powered: false,
    battery_level: 100,
    deleted_at: "",
    secure: true,
  },
  {
    id: 15,
    name: "Dining Room Light",
    internal_name: "Dimmer",
    room_id: "",
    type: "switch_multilevel",
    installation_status: "complete",
    attributes: [
      {
        id: 123,
        remote_id: null,
        last_read_state: "100",
        last_read_state_changed_at: null,
        name: "level",
      },
    ],
    inserted_at: "",
    online: true,
    warning: false,
    battery_powered: false,
    battery_level: 100,
    deleted_at: "",
    secure: true,
  },
  {
    id: 30,
    name: "Switch",
    internal_name: "Switch",
    room_id: "",
    type: "switch_binary",
    installation_status: "complete",
    attributes: [
      {
        id: 123,
        remote_id: null,
        last_read_state: "true",
        last_read_state_changed_at: null,
        name: "on",
      },
    ],
    inserted_at: "",
    online: true,
    warning: false,
    battery_powered: false,
    battery_level: 100,
    deleted_at: "",
    secure: true,
  },
  {
    id: 50,
    name: "Siren",
    internal_name: "Siren",
    room_id: "",
    type: "siren",
    installation_status: "complete",
    attributes: [
      {
        id: 123,
        remote_id: null,
        last_read_state: "false",
        last_read_state_changed_at: null,
        name: "siren_active",
      },
    ],
    inserted_at: "",
    online: true,
    warning: false,
    battery_powered: true,
    battery_level: 10,
    deleted_at: "",
    secure: true,
  },
  {
    id: 75,
    name: "Shades",
    internal_name: "Shades",
    room_id: "",
    type: "shade_control",
    installation_status: "complete",
    attributes: [
      {
        id: 123,
        remote_id: null,
        last_read_state: "100",
        last_read_state_changed_at: null,
        name: "level",
      },
    ],
    inserted_at: "",
    online: true,
    warning: false,
    battery_powered: false,
    battery_level: 100,
    deleted_at: "",
    secure: true,
  },
  {
    id: 100,
    name: "Motion Sensor",
    internal_name: "Motion Sensor",
    room_id: "",
    type: "sensor_notification",
    installation_status: "complete",
    attributes: [
      {
        id: 123,
        remote_id: null,
        last_read_state: "false",
        last_read_state_changed_at: null,
        name: "motion_binary",
      },
    ],
    inserted_at: "",
    online: false,
    warning: false,
    battery_powered: true,
    battery_level: 10,
    deleted_at: "",
    secure: true,
  },
  {
    id: 110,
    name: "Leak Sensor",
    internal_name: "Leak Sensor",
    room_id: "",
    type: "sensor_notification",
    installation_status: "complete",
    attributes: [
      {
        id: 123,
        remote_id: null,
        last_read_state: "false",
        last_read_state_changed_at: null,
        name: "leak",
      },
    ],
    inserted_at: "",
    online: false,
    warning: false,
    battery_powered: true,
    battery_level: 100,
    deleted_at: "",
    secure: true,
  },
  {
    id: 120,
    name: "Contact Sensor",
    internal_name: "Contact Sensor",
    room_id: "",
    type: "sensor_notification",
    installation_status: "complete",
    attributes: [
      {
        id: 123,
        remote_id: null,
        last_read_state: "false",
        last_read_state_changed_at: null,
        name: "contact_broken",
      },
    ],
    inserted_at: "",
    online: true,
    warning: false,
    battery_powered: true,
    battery_level: 100,
    deleted_at: "",
    secure: true,
  },
  {
    id: 150,
    name: "Repeater",
    internal_name: "Repeater",
    room_id: "",
    type: "repeater_slave",
    installation_status: "complete",
    attributes: [
      {
        id: 123,
        remote_id: null,
        last_read_state: unitHumidity.toString(),
        last_read_state_changed_at: null,
        name: "current_humidity",
      },
      {
        id: 323,
        remote_id: null,
        last_read_state: unitTemperature.toString(),
        last_read_state_changed_at: null,
        name: "current_temp",
      },
    ],
    inserted_at: "",
    online: true,
    warning: false,
    battery_powered: false,
    battery_level: 100,
    deleted_at: "",
    secure: true,
  },
  {
    id: 175,
    name: "Thermostat",
    internal_name: "Thermostat",
    room_id: "",
    type: "thermostat",
    installation_status: "complete",
    paused: false,
    attributes: [
      // {
      //   id: 123,
      //   name: "mode",
      //   remote_id: null,
      //   last_read_state: "auto",
      //   last_read_state_changed_at: null,
      // },
      // {
      //   id: 623,
      //   name: "fan_mode",
      //   remote_id: null,
      //   last_read_state: "auto",
      //   last_read_state_changed_at: null,
      // },
      // {
      //   id: 223,
      //   name: "cooling_setpoint",
      //   remote_id: null,
      //   last_read_state: "78",
      //   last_read_state_changed_at: null,
      // },
      // {
      //   id: 323,
      //   name: "heating_setpoint",
      //   remote_id: null,
      //   last_read_state: "70",
      //   last_read_state_changed_at: null,
      // },
      // {
      //   id: 423,
      //   name: "current_temp",
      //   remote_id: null,
      //   last_read_state: unitTemperature.toString(),
      //   last_read_state_changed_at: null,
      // },
      // {
      //   id: 523,
      //   name: "current_humidity",
      //   remote_id: null,
      //   last_read_state: unitHumidity.toString(),
      //   last_read_state_changed_at: null,
      // },
      // {
      //   id: 623,
      //   name: "operating_state",
      //   remote_id: null,
      //   last_read_state: "heating",
      //   last_read_state_changed_at: null,
      // },
      {
        id: 1,
        last_read_state: "75",
        last_read_state_changed_at: "2022-10-31T19:38:15Z",
        name: "cooling_setpoint",
        remote_id: "1",
      },
      {
        id: 1,
        last_read_state: "43",
        last_read_state_changed_at: "2022-10-31T19:39:08Z",
        name: "current_humidity",
        remote_id: "1",
      },
      {
        id: 1,
        last_read_state: "auto",
        last_read_state_changed_at: "2022-10-31T10:24:07Z",
        name: "mode",
        remote_id: "1",
      },
      {
        id: 1,
        last_read_state: "auto",
        last_read_state_changed_at: "2022-10-31T10:23:34Z",
        name: "fan_mode",
        remote_id: "1",
      },
      {
        id: 1,
        last_read_state: "70",
        last_read_state_changed_at: "2022-10-31T10:23:45Z",
        name: "heating_setpoint",
        remote_id: "1",
      },
      {
        id: 1,
        last_read_state: "68",
        last_read_state_changed_at: "2022-10-31T18:21:29Z",
        name: "current_temp",
        remote_id: "1",
      },
      {
        id: 1,
        last_read_state: "62.0",
        last_read_state_changed_at: "2020-08-10T21:02:36Z",
        name: "NOT_MAPPED_ENERGY_SAVE_HEATING",
        remote_id: "1",
      },
      {
        id: 1,
        last_read_state: "85.0",
        last_read_state_changed_at: "2020-08-10T21:02:37Z",
        name: "NOT_MAPPED_ENERGY_SAVE_COOLING",
        remote_id: "1",
      },
    ],
    ui_control_options: [
      {
        name: "auto_differential",
        value: 2,
      },
      {
        name: "min_cool_setpoint",
        value: 60,
      },
      {
        name: "max_heat_setpoint",
        value: 80,
      },
    ],
    inserted_at: "",
    online: true,
    warning: false,
    battery_powered: true,
    battery_level: 100,
    deleted_at: "",
    secure: true,
  },
  {
    id: 200,
    name: "Flow Sensor",
    internal_name: "Flow Sensor",
    room_id: "",
    type: "sensor_flow",
    installation_status: "complete",
    attributes: [
      {
        id: 123,
        remote_id: null,
        last_read_state: "true",
        last_read_state_changed_at: null,
        name: "open",
      },
      {
        id: 456,
        remote_id: null,
        last_read_state: "false",
        last_read_state_changed_at: null,
        name: "leak",
      },
    ],
    inserted_at: "",
    online: true,
    warning: false,
    battery_powered: true,
    battery_level: 100,
    deleted_at: "",
    secure: true,
  },
];

export const getDeviceStorageKey = (deviceId: number) => `device:${deviceId}`;

export function getDevice(deviceId: number): DeviceProps {
  const item = localStorage.getItem(getDeviceStorageKey(deviceId));
  return item
    ? JSON.parse(item)
    : (devices.find((device) => device.id === deviceId) as DeviceProps);
}

export function setDevicePaused(deviceId: number, enabled: boolean): void {
  const device = getDevice(deviceId);

  localStorage.setItem(
    getDeviceStorageKey(deviceId),
    JSON.stringify({
      ...device,
      paused: enabled,
    })
  );

  setTimeout(() => {
    emitter.emit(`devices:${deviceId}`, { eventName: "deviceAttributeUpdate" });
  }, 2500);
}

export function setDeviceAttributes(
  deviceId: number,
  attributes: DeviceAttributeProps[]
): void {
  const device = getDevice(deviceId);

  localStorage.setItem(
    getDeviceStorageKey(deviceId),
    JSON.stringify({
      ...device,
      attributes,
    })
  );

  setTimeout(() => {
    emitter.emit(`devices:${deviceId}`, { eventName: "deviceAttributeUpdate" });
  }, 2500);

  return void 0;
}
