import users from "./users";
import usersSections from "./users-sections";
import workOrders from "./work-orders";
import projects from "./projects";
import units from "./units";
import chartData from "./chart-data";

export const handlers = [
  ...users,
  ...usersSections,
  ...workOrders,
  ...projects,
  ...units,
  ...chartData,
];
