import { HttpResponse, delay, http } from "msw";
import faker from "faker";
import Fuse from "fuse.js";

import { getDataByPage } from "../utils";

import type { UserType } from "../types";

faker.seed(1);

export const getUser: () => UserType = () => {
  return {
    id: faker.random.uuid(),
    first_name: faker.name.firstName(),
    last_name: faker.name.lastName(),
  };
};
export const users = new Array(100).fill({}).map(getUser);

export default [
  http.get("/api/users", async ({ request }) => {
    const url = new URL(request.url);
    const page = Number(url.searchParams.get("page") || "1");
    const query = url.searchParams.get("query");
    const ids: string[] = (url.searchParams.get("ids") ?? "")
      .split(",")
      .filter(Boolean);
    const perPage = Number(url.searchParams.get("perPage") || "10");
    const totalPages =
      users.length / perPage > 1 ? Math.round(users.length / perPage) : 1;

    if (page > totalPages) {
      return new HttpResponse(null, { status: 400 });
    }

    if (ids.length > 0) {
      const records = users.filter((user) => {
        return ids.includes(user.id);
      });

      await delay();

      return HttpResponse.json({
        total_pages: 1,
        // doesn't support multiple pages as we're just mocking data here
        current_page: 1,
        total_records: users.length,
        records,
      });
    }

    if (query) {
      const fuse = new Fuse(users, {
        distance: 100,
        findAllMatches: true,
        threshold: 0.5,
        keys: ["id", "first_name", "last_name"],
        shouldSort: true,
      });

      const results = fuse.search(query);

      await delay();
      return HttpResponse.json({
        total_pages:
          results.length / perPage > 1
            ? Math.round(results.length / perPage)
            : 1,
        current_page: page,
        total_records: results.length,
        records: getDataByPage(
          results.map((result) => {
            return result.item;
          }),
          page,
          perPage
        ),
      });
    }

    await delay();
    return HttpResponse.json({
      total_pages: totalPages,
      current_page: page,
      total_records: users.length,
      records: getDataByPage(users, page, perPage),
    });
  }),
  http.get("/api/users/:userId", async ({ params }) => {
    const { userId } = params;

    const user = users.find((user) => {
      return user.id == userId;
    });

    if (!user) {
      return new HttpResponse("No user found", { status: 400 });
    }

    await delay();
    return HttpResponse.json(user);
  }),
];
