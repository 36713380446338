import { HttpResponse, http } from "msw";
import faker from "faker";
import Fuse from "fuse.js";

import { getDataByPage } from "../utils";

import type { UserType } from "../types";

faker.seed(1);

const sections = ["Manager", "Resident", "Maintenance", "Guest"];
const getUser: () => UserType = () => {
  return {
    id: faker.random.uuid(),
    first_name: faker.name.firstName(),
    last_name: faker.name.lastName(),
    section: sections[faker.random.number(sections.length - 1)],
  };
};
const users = new Array(25).fill({}).map(getUser);

export default [
  http.get("/api/users-sections", ({ request }) => {
    const url = new URL(request.url);
    const page = Number(url.searchParams.get("page") || "1");
    const query = url.searchParams.get("query");

    const perPage = 25;
    const totalPages = users.length / perPage;

    if (page > totalPages) {
      return new HttpResponse(null, { status: 400 });
    }

    if (query) {
      const fuse = new Fuse(users, {
        distance: 100,
        findAllMatches: true,
        threshold: 0.4,
        keys: ["id", "first_name", "last_name"],
        shouldSort: true,
      });

      const results = fuse.search(query);

      return HttpResponse.json({
        total_pages: totalPages,
        current_page: page,
        total_records: users.length,
        records: getDataByPage(
          results.map((result) => {
            return result.item;
          }),
          page,
          perPage
        ),
      });
    }

    return HttpResponse.json({
      total_pages: totalPages,
      current_page: page,
      total_records: users.length,
      records: getDataByPage(users, page, perPage),
    });
  }),
];
