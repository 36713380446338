import { http, delay, HttpResponse } from "msw";
import faker from "faker";

faker.seed(1);

export default [
  http.get("/api/tours-day", async ({ request }) => {
    await delay(1200);

    return HttpResponse.json([
      { day: "monday", tours: faker.random.number({ min: 1500, max: 5000 }) },
      {
        day: "tuesday",
        tours: faker.random.number({ min: 1500, max: 5000 }),
      },
      {
        day: "wednesday",
        tours: faker.random.number({ min: 1500, max: 5000 }),
      },
      {
        day: "thursday",
        tours: faker.random.number({ min: 1500, max: 5000 }),
      },
      { day: "friday", tours: faker.random.number({ min: 1500, max: 5000 }) },
      {
        day: "saturday",
        tours: faker.random.number({ min: 1500, max: 5000 }),
      },
      { day: "sunday", tours: faker.random.number({ min: 1500, max: 5000 }) },
    ]);
  }),

  http.get("/api/tours-year", async (info) => {
    await delay(1200);

    return HttpResponse.json([
      {
        year: "2017",
        tours: faker.random.number(),
        selfGuided: faker.random.number(),
      },
      {
        year: "2018",
        tours: faker.random.number(),
        selfGuided: faker.random.number(),
      },
      {
        year: "2019",
        tours: faker.random.number(),
        selfGuided: faker.random.number(),
      },
      {
        year: "2020",
        tours: faker.random.number(),
        selfGuided: faker.random.number(),
      },
      {
        year: "2021",
        tours: faker.random.number(),
        selfGuided: faker.random.number(),
      },
      {
        year: "2022",
        tours: faker.random.number(),
        selfGuided: faker.random.number(),
      },
      {
        year: "2023",
        tours: faker.random.number(),
        selfGuided: faker.random.number(),
      },
    ]);
  }),

  http.get("/api/chart-data", async (info) => {
    await delay(1200);
    return HttpResponse.json([
      {
        day_of_week: "Monday",
        number_of_visits: faker.random.number({ min: 1000, max: 5000 }),
        sales: faker.random.number({ min: 1000, max: 5000 }),
        returns: faker.random.number({ min: 1000, max: 5000 }),
      },
      {
        day_of_week: "Tuesday",
        number_of_visits: faker.random.number({ min: 1000, max: 5000 }),
        sales: faker.random.number({ min: 1000, max: 5000 }),
        returns: faker.random.number({ min: 1000, max: 5000 }),
      },
      {
        day_of_week: "Wednesday",
        number_of_visits: faker.random.number({ min: 1000, max: 5000 }),
        sales: faker.random.number({ min: 1000, max: 5000 }),
        returns: faker.random.number({ min: 1000, max: 5000 }),
      },
      {
        day_of_week: "Thursday",
        number_of_visits: faker.random.number({ min: 1000, max: 5000 }),
        sales: faker.random.number({ min: 1000, max: 5000 }),
        returns: faker.random.number({ min: 1000, max: 5000 }),
      },
      {
        day_of_week: "Friday",
        number_of_visits: faker.random.number({ min: 1000, max: 5000 }),
        sales: faker.random.number({ min: 1000, max: 5000 }),
        returns: faker.random.number({ min: 1000, max: 5000 }),
      },
      {
        day_of_week: "Saturday",
        number_of_visits: faker.random.number({ min: 1000, max: 5000 }),
        sales: faker.random.number({ min: 1000, max: 5000 }),
        returns: faker.random.number({ min: 1000, max: 5000 }),
      },
      {
        day_of_week: "Sunday",
        number_of_visits: faker.random.number({ min: 1000, max: 5000 }),
        sales: faker.random.number({ min: 1000, max: 5000 }),
        returns: faker.random.number({ min: 1000, max: 5000 }),
      },
    ]);
  }),
];
