import {
  deviceDesiredAttributesMergeToAttributes,
  deviceThermostatAttributesToOperatingStateFallback,
  deviceThermostatAttributesToProperties,
  getDeviceAttribute,
} from "@smartrent/core";

import {
  devices,
  getDevice as _getDevice,
  getDeviceStorageKey,
  setDeviceAttributes,
  setDevicePaused,
} from "./utils";
import { delay, getDelayAmount } from "../utils";

import type { DeviceAttributeUpdate } from "@smartrent/core";

export async function setupDevices() {
  for (const device of devices) {
    if (!localStorage.getItem(getDeviceStorageKey(device.id))) {
      localStorage.setItem(
        getDeviceStorageKey(device.id),
        JSON.stringify(device)
      );
    }
  }
}

export async function getDevices({ unitId }: { unitId: number }) {
  // we don't do anything with the unitId param (just for mocking purposes)
  await delay(getDelayAmount({ min: 250, max: 1500 }));

  return {
    total_pages: 1,
    current_page: 1,
    total_records: devices.length,
    records: devices.map((device) => {
      return _getDevice(device.id);
    }),
  };
}

export async function getDevice({
  deviceId,
  simulateDelay = true,
}: {
  deviceId: number;
  simulateDelay?: boolean;
}) {
  if (simulateDelay) {
    await delay();
  }

  const device = _getDevice(deviceId);

  if (!device) {
    throw new Error("No Device Found");
  }

  return device;
}

export async function updateDevice({
  deviceId,
  attributes,
}: {
  deviceId: number;
  attributes: DeviceAttributeUpdate[];
}) {
  await delay(getDelayAmount({ min: 150, max: 650 }));

  const device = await getDevice({ deviceId, simulateDelay: false });

  if (!device) {
    throw new Error("No Device Found");
  }

  let newAttributes = deviceDesiredAttributesMergeToAttributes({
    desiredAttributes: attributes,
    currentAttributes: device.attributes,
  });

  // this will keep the "operating_state" attribute update to date
  // with any incoming updates :)
  if (device.type === "thermostat") {
    const properties = deviceThermostatAttributesToProperties(newAttributes);
    const operatingState = deviceThermostatAttributesToOperatingStateFallback({
      temperature: properties.temperature,
      coolingSetpoint: properties.coolingSetpoint,
      heatingSetpoint: properties.heatingSetpoint,
      mode: getDeviceAttribute("mode", newAttributes),
    });

    const repeaterDevice = devices.find(
      (device) => device.internal_name === "Repeater"
    );
    if (repeaterDevice) {
      setDeviceAttributes(
        repeaterDevice.id,
        repeaterDevice.attributes.map((attr) => {
          switch (attr.name) {
            case "current_temp":
              return {
                ...attr,
                last_read_state: properties.temperature
                  ? properties.temperature?.toString()
                  : attr.last_read_state,
              };

            case "current_humidity":
              return {
                ...attr,
                last_read_state: properties.humidity
                  ? properties.humidity?.toString()
                  : attr.last_read_state,
              };

            default:
              return attr;
          }
        })
      );
    }

    if (operatingState) {
      newAttributes = newAttributes.map((attr) => {
        if (attr.name === "operating_state") {
          return {
            ...attr,
            last_read_state: operatingState,
          };
        }

        return attr;
      });
    }
  }

  setDeviceAttributes(deviceId, newAttributes);

  return device;
}

export async function pauseAutomations({
  deviceTypes,
  enabled,
}: {
  deviceTypes: string[];
  enabled: boolean;
}) {
  await delay();

  if (deviceTypes.includes("thermostat")) {
    const thermostatDevices = devices
      .filter((d) => d.type === "thermostat")
      .map((d) => d.id);

    for (const deviceId of thermostatDevices) {
      setDevicePaused(deviceId, enabled);
    }
  }

  return true;
}
