import * as formatters from "@smartrent/formatters";
import * as utils from "@smartrent/utils";

declare global {
  interface Window {
    formatters: any;
    utils: any;
  }
}

window.formatters = formatters;
window.utils = utils;
