import { HttpResponse, delay, http } from "msw";
import faker from "faker";
import Fuse from "fuse.js";

import { getDataByPage } from "../utils";
import { users } from "./users";
import { units } from "./units";

import type {
  PermissionToEnterType,
  PriorityType,
  StatusType,
  WorkOrderType,
} from "../types";

faker.seed(1);

const getWorkOrder: () => WorkOrderType = () => {
  const locations = [
    "Bathroom",
    "Kitchen",
    "Living Room",
    "Laundry Room",
    "Bedroom",
  ];
  const priorities = ["Emergency", "High", "Medium", "Low"];
  const statuses = ["Complete", "Canceled", "Open", "Scheduled", "In Progress"];
  const permissions = ["Anytime", "Scheduled", "Call Required"];

  return {
    id: faker.random.uuid(),
    units: units[faker.random.number(99)],
    assigned_employee: users[faker.random.number(99)],
    assigned_vendor: {
      name: faker.company.companyName(),
      id: faker.random.uuid(),
    },
    issue_location: locations[faker.random.number(locations.length - 1)],
    priority: priorities[
      faker.random.number(priorities.length - 1)
    ] as PriorityType,
    service_problem: faker.company.catchPhrase(),
    status: statuses[faker.random.number(statuses.length - 1)] as StatusType,
    permission_to_enter: permissions[
      faker.random.number(permissions.length - 1)
    ] as PermissionToEnterType,
    comments: faker.random.words(10),
    entry_notes: faker.random.words(15),
    scheduled_date: faker.date.future().toString(),
    inserted_at: faker.date.past().toString(),
  };
};

const workOrders = new Array(100).fill({}).map(getWorkOrder);

export default [
  http.get("/api/work-orders", async ({ request }) => {
    const url = new URL(request.url);
    const page = Number(url.searchParams.get("page") || "1");
    const employeeQuery = url.searchParams.get("assigned_employee");
    const unitQuery = url.searchParams.get("units");

    const perPage = 10;
    const totalPages =
      workOrders.length / perPage > 1
        ? Math.round(workOrders.length / perPage)
        : 1;

    if (page > totalPages) {
      return new HttpResponse(null, { status: 400 });
    }

    let results = [...workOrders];

    if (employeeQuery) {
      const fuse = new Fuse(results, {
        distance: 100,
        findAllMatches: true,
        threshold: 0.5,
        keys: ["assigned_employee.id"],
      });

      results = fuse.search(employeeQuery).map((result) => result.item);
    }

    if (unitQuery) {
      const fuse = new Fuse(results, {
        distance: 100,
        findAllMatches: true,
        threshold: 0.5,
        keys: ["units.id"],
      });

      results = fuse.search(unitQuery).map((result) => result.item);
    }

    await delay();
    return HttpResponse.json({
      total_pages:
        results.length / perPage > 1 ? Math.round(results.length / perPage) : 1,
      current_page: page,
      total_records: results.length,
      records: getDataByPage(results, page, perPage),
    });
  }),
];
