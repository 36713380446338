import { HttpResponse, http } from "msw";
import faker from "faker";
import Fuse from "fuse.js";
import { addDays, format } from "date-fns";
import { sample } from "lodash-es";

import { getProjectStatusText } from "@smartrent/install";

import { getDataByPage } from "../utils";

import type { InstallProjectProps } from "@smartrent/install";

faker.seed(1);

export const properties = new Array(7).fill({}).map(() => {
  return {
    name: faker.company.companyName(),
    remote_id: String(faker.random.number()),
    id: faker.random.uuid(),
  };
});

const getProject = (): InstallProjectProps => {
  const createdBy = {
    email: faker.internet.exampleEmail(),
    first_name: faker.name.firstName(),
    id: faker.random.uuid(),
    last_name: faker.name.lastName(),
    phone: faker.phone.phoneNumber(),
  };
  const property = properties[Math.floor(Math.random() * properties.length)];
  const completedJobCount = faker.random.number({ min: 0, max: 10 });
  const jobCount = faker.random.number({
    min: completedJobCount,
    max: completedJobCount + 4,
  });
  const today = addDays(new Date(), 10);
  const startDate = format(
    faker.date.between("2021-01-01", today),
    "yyyy-MM-dd"
  );
  const endDate = format(faker.date.between(today, "2022-01-01"), "yyyy-MM-dd");
  const completed = faker.random.boolean()
    ? format(faker.date.between("2021-04-01", "2022-01-01"), "yyyy-MM-dd")
    : null;
  const cannotComplete = completed
    ? faker.random.boolean()
      ? "kfsdjlfk"
      : null
    : null;
  const completedBy = completed ? createdBy.id : null;

  return {
    cannot_complete_reason: cannotComplete,
    completed_at: completed,
    open_flags_count: 0,
    completed_by_id: completedBy,
    completed_job_count: completedJobCount,
    end_date: endDate,
    id: faker.random.uuid(),
    job_count: jobCount,
    name: `${createdBy.last_name} Project`,
    property: property,
    property_id: property.id,
    start_date: startDate,
    status:
      sample(["completed", "pending", "overdue", "cannot-complete"]) ||
      "overdue",
    deleted_at: null,
    users: [createdBy],
    created_by: createdBy,
    install_details: null,
  };
};

const projects = new Array(100).fill({}).map(getProject);

export default [
  http.get("/api/projects", async ({ request }) => {
    const url = new URL(request.url);
    const page = Number(url.searchParams.get("page") || "1");
    const limit = Number(url.searchParams.get("limit") || "10");
    const sort = url.searchParams.get("sort") as
      | "name"
      | "status"
      | "property_name"
      | "start_date"
      | "end_date"
      | null;
    const sortDir = url.searchParams.get("dir") || "asc";

    const nameQuery = url.searchParams.get("name"); // todo: support all filters
    const statusQuery = url.searchParams.get("status");
    const propertyQuery = url.searchParams.get("property_name");
    const startDateQuery = url.searchParams.get("start_date");
    const endDateQuery = url.searchParams.get("end_date");

    let results = [...projects];
    // Fuzzy search when we pass any sort of filters to this endpoint
    // e.g. name, status, property, start date, end date
    if (nameQuery) {
      const fuse = new Fuse(results, {
        distance: 100,
        findAllMatches: true,
        threshold: 0.4,
        keys: ["name"],
      });

      results = fuse.search(nameQuery).map((result) => {
        return result.item;
      });
    }

    if (statusQuery) {
      const statusArray = statusQuery.split(",");

      results = results.filter((result) => {
        const status = getProjectStatusText(result);
        return statusArray.includes(status);
      });
    }

    if (propertyQuery) {
      const curr_property = properties.filter((prop) => {
        return prop.id === propertyQuery;
      })[0];

      results = results.map((result) => {
        return { ...result, property_name: result.property.name };
      });

      const fuse = new Fuse(results, {
        distance: 100,
        findAllMatches: true,
        threshold: 0.4,
        keys: ["property_name"],
      });

      results = fuse.search(curr_property.name).map((result) => {
        return result.item;
      });
    }

    if (startDateQuery) {
      results = results.filter((result) => {
        return startDateQuery < result.start_date;
      });
    }

    if (endDateQuery) {
      results = results.filter((result) => {
        return endDateQuery < result.end_date;
      });
    }
    // We need to sort the results if the user is sorting on columns
    // let sortedProjects: InstallProjectProps[] = [];
    if (sort && sortDir) {
      results = results.sort((a, b) => {
        const sorterA = { sort: "" };
        const sorterB = { sort: "" };

        if (sort === "status") {
          sorterA.sort = a.status;
          sorterB.sort = b.status;
        } else if (sort === "property_name") {
          sorterA.sort = a.property.name;
          sorterB.sort = b.property.name;
        } else {
          sorterA.sort = a[sort];
          sorterB.sort = b[sort];
        }

        if (sortDir === "asc") {
          return sorterA.sort > sorterB.sort ? 1 : -1;
        } else {
          return sorterA.sort < sorterB.sort ? 1 : -1;
        }
      });
    }
    const totalPages = Math.ceil(results.length / limit);

    if (page > totalPages) {
      return new HttpResponse(null, { status: 400 });
    }

    return HttpResponse.json({
      total_pages: totalPages,
      current_page: page,
      total_records: results.length,
      records: getDataByPage(results, page, limit),
    });
  }),
];
