import { HttpResponse, delay, http } from "msw";
import faker from "faker";
import Fuse from "fuse.js";

import { getDataByPage } from "../utils";

import type { UnitType } from "../types";

faker.seed(1);

export const getUnit: (idx: number) => UnitType = (idx) => {
  return {
    id: faker.random.uuid(),
    marketing_name: `Unit ${idx + 1}`,
  };
};
export const units = new Array(100).fill({}).map((_, idx) => getUnit(idx));

export default [
  http.get("/api/units", async ({ request }) => {
    const url = new URL(request.url);
    const page = Number(url.searchParams.get("page") || "1");
    const query = url.searchParams.get("query");
    const ids: string[] = (url.searchParams.get("ids") ?? "")
      .split(",")
      .filter(Boolean);

    const perPage = 10;
    const totalPages =
      units.length / perPage > 1 ? Math.round(units.length / perPage) : 1;

    if (page > totalPages) {
      return new HttpResponse(null, { status: 400 });
    }

    if (ids.length > 0) {
      const records = units.filter((unit) => {
        return ids.includes(unit.id);
      });

      await delay();

      return HttpResponse.json({
        total_pages: 1,
        // doesn't support multiple pages as we're just mocking data here
        current_page: 1,
        total_records: units.length,
        records,
      });
    }

    if (query) {
      const fuse = new Fuse(units, {
        distance: 100,
        findAllMatches: true,
        threshold: 0.2,
        minMatchCharLength: 1,
        keys: ["marketing_name"],
        shouldSort: true,
      });

      const results = fuse.search(query);

      await delay();

      return HttpResponse.json({
        total_pages:
          results.length / perPage > 1
            ? Math.round(results.length / perPage)
            : 1,
        current_page: page,
        total_records: results.length,
        records: getDataByPage(
          results.map((result) => {
            return result.item;
          }),
          page,
          perPage
        ),
      });
    }

    await delay();
    return HttpResponse.json({
      total_pages: totalPages,
      current_page: page,
      total_records: units.length,
      records: getDataByPage(units, page, perPage),
    });
  }),
  http.get("/api/units/:unitId", async ({ params }) => {
    const { unitId } = params;

    const unit = units.find((unit) => {
      return unit.id == unitId;
    });

    if (!unit) {
      return new HttpResponse("No user found", { status: 400 });
    }

    await delay();
    return HttpResponse.json(unit);
  }),
];
