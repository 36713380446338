export const getDataByPage: <TData>(
  data: TData[],
  page: number,
  perPage: number
) => TData[] = (data, page, perPage) => {
  return data.slice(
    page > 1 ? (page - 1) * perPage : 0,
    (page - 1) * perPage + perPage
  );
};
